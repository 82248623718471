import React from "react"
import styles from "./VideoBanner.module.scss"

const VideoBanner = () => {
  return (
    <section className={styles.slider}>
      <video
        autoPlay
        loop
        muted
        playsInline
        width="100%"
        src="https://api.aramaze.am/uploads/Home_Video_b8ad0823e8.mp4"
      />
      <div className={styles.contentHolder}>
        <div className={styles.content}>
          <h2>
            bringing <strong>amazing software</strong> solutions{" "}
            <strong>together</strong>
          </h2>
        </div>
      </div>
    </section>
  )
}

export default VideoBanner
