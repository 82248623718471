import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Icon from "../../Icon"
import styles from "./Card.module.scss"

const Card = ({ image, to, title }) => (
  <Link className={styles.listItem} to={to}>
    <div className={styles.card}>
      <div className={styles.button}>
        <Icon icon="arrow-right" />
        <div className={styles.circle}></div>
      </div>
      <Img
        fluid={image?.childImageSharp.fluid}
        className={styles.image}
        imgStyle={{ objectFit: "fill" }}
      />
    </div>
  </Link>
)

export default Card
