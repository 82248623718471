import React from "react"
import { graphql } from "gatsby"

import HomePage from "../components/HomePage"

const IndexPage = ({ data }) => {
  const services =
    data?.allStrapiService?.edges?.map(edge => ({ ...edge.node })) || []
  const technologies =
    data?.allStrapiTechnology?.edges?.map(edge => ({ ...edge.node })) || []
  const banners =
    data?.allStrapiHomeSlide?.edges?.map(edge => ({ ...edge.node })) || []
  const slides =
    data?.allStrapiHomeBanner?.edges?.map(edge => ({ ...edge.node })) || []
  const projects =
    data?.allStrapiProject?.edges?.map(edge => ({ ...edge.node })) || []

  return (
    <HomePage
      services={services}
      technologies={technologies}
      banners={banners}
      slides={slides}
      projects={projects}
    />
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiService(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          icon
          slug
          title
          shortDescription
        }
      }
    }
    allStrapiTechnology(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          icon
          slug
          title
          shortDescription
        }
      }
    }
    allStrapiHomeSlide {
      edges {
        node {
          id
          description
        }
      }
    }
    allStrapiHomeBanner {
      edges {
        node {
          id
          text
          Link {
            Label
            Url
          }
          image {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allStrapiProject {
      edges {
        node {
          id
          title
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
