import React, { useEffect, useRef, useReducer, useState } from "react"

import styles from "./Facts.module.scss"

const initialState = {
  professionals: 0,
  clients: 0,
  projects: 0,
  users: 0,
  isInitialized: false,
  isVisible: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "setVisibility":
      return { ...state, isVisible: true }
    case "setInitialized":
      return { ...state, isInitialized: true }
    case "increment":
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}

const Facts = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [intervalId, setIntervalId] = useState()
  const contRef = useRef()
  const {
    professionals,
    clients,
    projects,
    users,
    isVisible,
    isInitialized,
  } = state

  useEffect(() => {
    return () => clearInterval(intervalId)
  }, [intervalId])

  useEffect(() => {
    function handleScroll() {
      if (isInitialized) return

      const rect = contRef.current.getBoundingClientRect()
      const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      )
      if (!(rect.bottom < 0 || rect.top - viewHeight >= 0)) {
        dispatch({ type: "setVisibility" })
      }
    }

    document.addEventListener("scroll", handleScroll)

    return () => document.removeEventListener("scroll", handleScroll)
  }, [isInitialized])

  useEffect(() => {
    if (isVisible && !isInitialized) {
      dispatch({ type: "setInitialized" })

      const professionalsMax = 76,
        clientsMax = 15,
        projectsMax = 54,
        usersMax = 2600
      let professionalsLocal = 0,
        clientsLocal = 0,
        projectsLocal = 0,
        usersLocal = 0

      setIntervalId(
        setInterval(() => {
          if (professionalsLocal < professionalsMax) ++professionalsLocal
          if (clientsLocal < clientsMax) ++clientsLocal
          if (projectsLocal < projectsMax) ++projectsLocal
          if (users < usersMax) usersLocal = usersLocal + 50

          dispatch({
            type: "increment",
            payload: {
              professionals: professionalsLocal,
              clients: clientsLocal,
              projects: projectsLocal,
              users: usersLocal > usersMax ? usersMax : usersLocal,
            },
          })

          if (
            clientsLocal === clientsMax &&
            professionalsLocal === professionalsMax &&
            usersLocal === usersMax &&
            projectsLocal === projectsMax
          ) {
            clearInterval(intervalId)
          }
        }, 25)
      )
    }
  }, [
    professionals,
    clients,
    projects,
    users,
    isVisible,
    isInitialized,
    intervalId,
  ])

  return (
    <section className={styles.factsWrap} ref={contRef}>
      <div className={styles.facts}>
        <div className={styles.items}>
          <h2>{professionals}</h2>
          <p>Team Members</p>
        </div>
        <div className={styles.items}>
          <h2>{clients}</h2>
          <p>Clients</p>
        </div>
        <div className={styles.items}>
          <h2>{projects}</h2>
          <p>Projects</p>
        </div>
        <div className={styles.items}>
          <h2>{users}+</h2>
          <p>Happy Users</p>
        </div>
      </div>
    </section>
  )
}

export default Facts
