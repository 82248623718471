import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import Icon from "../Icon"
import styles from "./Card.module.scss"

// complementary
const Card = ({ title, children, icon, to, color = "primary" }) => (
  <div className={styles.container}>
    <Link to={to} className={cx(styles.content, styles[color])}>
      <div className={styles.icon}>
        <i>
          <Icon icon={icon} size={25} />
        </i>
      </div>
      <h3>{title}</h3>
      <p>{children}</p>
    </Link>
  </div>
)

export default Card
