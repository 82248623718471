import React from "react"
import BannerAnim from "rc-banner-anim"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"

import "rc-banner-anim/assets/index.css"
import "./RcBannerAnim.css"
import styles from "./Slider.module.scss"

const { Element, Thumb } = BannerAnim
const BgElement = Element.BgElement

const Slider = ({ slides }) => {
  return (
    <section className={styles.container}>
      <BannerAnim
        type={["grid"]}
        arrow={false}
        autoPlay
        autoPlaySpeed={20000}
        duration={400}
      >
        {slides.map(slide => (
          <Element key={slide.id}>
            <BgElement
              key="bg"
              className={styles.bg}
              style={{
                backgroundImage: `url(${slide.image?.childImageSharp?.fluid?.src})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <div key="content" className={styles.content}>
              <ReactMarkdown source={slide.text} className={styles.markdown} />
              {slide?.Link && (
                <Link to={slide.Link.Url}>{slide.Link.Label}</Link>
              )}
            </div>
          </Element>
        ))}

        <Thumb>
          {slides.map(slide => (
            <span key={slide.id} className="thumbBtn">
              <i />
            </span>
          ))}
        </Thumb>
      </BannerAnim>
    </section>
  )
}

export default Slider
