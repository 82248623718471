import React from "react"

import Card from "../Card"
import styles from "./Services.module.scss"

const Services = ({ services }) => {
  return (
    <section className={styles.wrap}>
      <div className={styles.section}>
        <div className={styles.header}>
          <h2 className={styles.title}>Our services</h2>
        </div>
        <div className={styles.services}>
          {services.map(service => (
            <Card
              color="complementary"
              key={service.id}
              title={service.title}
              icon={service.icon}
              to={`/services/${service.slug}/`}
            >
              {service.shortDescription}
            </Card>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Services
