import React from "react"

import Card from "../Card"
import styles from "./Technology.module.scss"

const Technology = ({ technologies }) => (
  <section className={styles.wrap}>
    <div className={styles.section}>
      <div className={styles.header}>
        <h2 className={styles.title}>Our Technologies</h2>
      </div>
      <div className={styles.technologies}>
        {technologies.map(technology => (
          <Card
            color="primary"
            key={technology.id}
            title={technology.title}
            icon={technology.icon}
            to={`/technologies/${technology.slug}/`}
          >
            {technology.shortDescription}
          </Card>
        ))}
      </div>
    </div>
  </section>
)

export default Technology
