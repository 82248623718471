import React, { useEffect } from "react"
import { Element, scrollSpy } from "react-scroll"

import Layout from "../Shared/Layout"
import VideoBanner from "../Shared/VideoBanner"
import Slider from "../Shared/Slider"
import Services from "../Shared/Services"
import Technology from "../Shared/Technology"
import ContactUsForm from "../Shared/ContactUsForm"
import Facts from "../Shared/Facts"
import Projects from "../Shared/Projects"
import SEO from "../Shared/Seo"

import styles from "./HomePage.module.scss"

const HomePage = ({ services, technologies, slides, banners, projects }) => {
  useEffect(() => scrollSpy.update(), [])

  return (
    <Layout>
      <SEO
        title="Custom Software Development, Design & Consulting"
        description="We help customers digitally transform their businesses through our unique blend of world-class software engineering, design and consulting services."
      />
      <div className={styles.wrap} id="scroll-container">
        <Element id="home">
          <VideoBanner banners={banners} />
          <Slider slides={slides} />
        </Element>
        <Element id="services">
          <Services services={services} />
        </Element>
        <Element id="technologies">
          <Technology technologies={technologies} />
        </Element>
        <Facts />
        <Element id="projects">
          <Projects projects={projects} />
        </Element>
        <div className={styles.contactsWrap}>
          <ContactUsForm className={styles.contacts} />
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
