import React from "react"

import Card from "./Card"
import styles from "./Projects.module.scss"

const Projects = ({ projects }) => {
  return (
    <section className={styles.wrap}>
      <div className={styles.section}>
        <div className={styles.header}>
          <h2 className={styles.title}>Our Portfolio</h2>
        </div>
        <div className={styles.list}>
          {projects.map(({ id, image, title, slug }) => (
            <Card
              key={id}
              image={image}
              title={title}
              to={`/projects/?project=${slug}`}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Projects
